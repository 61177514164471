const isAdmin = (store) => {
  const loggedInUser = store.getters.loggedInUser
  return loggedInUser && loggedInUser.role?.name === 'Admin'
}

const isOnBoarded = (store) => {
  const loggedInUser = store.getters.loggedInUser

  const { client } = loggedInUser
  return (
    loggedInUser.firstName &&
    loggedInUser.lastName &&
    loggedInUser.email &&
    loggedInUser.phone &&
    client &&
    client.kvk &&
    client.address &&
    client.contact_email &&
    client.postalcode &&
    client.city &&
    client.state &&
    client.country &&
    client.vat_number &&
    client.contact_email &&
    !isNaN(client.km_compensation) &&
    !isNaN(client.max_km) &&
    client.locations &&
    client.locations.length > 0 &&
    loggedInUser.status === 'active'
  )
}

export default function (context) {
  const { store, $auth, next, localePath } = context
  if (!store || !$auth || !next || !localePath) {
    return true
  }

  if (isAdmin(store)) {
    return true
  }

  const onBoarded = isOnBoarded(store)
  if (!onBoarded) {
    return next({ path: localePath('/client-area/onboarding') })
  }
}
