const getDefaultState = () => {
  return {}
}

export default {
  namespaced: true,
  name: 'confetti',
  state() {
    return {
      confetti: {}
    }
  },
  mutations: {
    setConfetti(state: any, showSnack: any) {
      state.confetti = { ...showSnack }
    },
    resetState(state: any) {
      Object.assign(state, getDefaultState())
    }
  }
}
