import { type ActionTree, type GetterTree, type MutationTree } from 'vuex'

import { Client } from './clients'
import { Freelancer } from './freelancer'
import favoriteFreelancerService from '~/services/favoriteFreelancerService'

export interface FavoriteFreelancer {
  id: number
  client: Client
  freelancer: Freelancer
  favorite: boolean
}

const getDefaultState = () => ({
  favorites: [] as FavoriteFreelancer[]
})

export const state = getDefaultState

export type FavoriteFreelancerState = ReturnType<typeof state>

export const mutations: MutationTree<FavoriteFreelancerState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  set(state, favoriteFreelancers: FavoriteFreelancer[]) {
    state.favorites = favoriteFreelancers
  },
  add(state, favoriteFreelancer: FavoriteFreelancer) {
    state.favorites.push(favoriteFreelancer)
  },
  update(state, data: any) {
    const favoriteFreelancer: FavoriteFreelancer | null | undefined =
      state.favorites.find(
        (o: FavoriteFreelancer) => o.freelancer.id === data.freelancer.id
      )
    if (favoriteFreelancer) {
      favoriteFreelancer.favorite = !favoriteFreelancer.favorite
    } else {
      state.favorites.push(data)
    }
  }
}

export const actions: ActionTree<
  FavoriteFreelancerState,
  FavoriteFreelancerState
> = {
  nuxtClientInit({ dispatch }, $this) {
    dispatch('findAll', $this)
  },
  async findAll({ commit }) {
    try {
      const response = await favoriteFreelancerService.findAll(this)
      const { data } = response
      commit('set', data)

      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },
  async toggle({ commit }, favoriteData) {
    try {
      const response = await favoriteFreelancerService.toggle(
        this,
        favoriteData
      )
      commit('update', response.data)

      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  }
}

export const getters: GetterTree<
  FavoriteFreelancerState,
  FavoriteFreelancerState
> = {
  favorites: (state) => state.favorites,
  favorite: (state) => (id: number) => {
    return state.favorites.find((favorite) => favorite.freelancer?.id === id)
  }
}
