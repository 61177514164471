import { ApiConfig } from '../config.js'

export default {
  async findOne($this: any, candidateId: number) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.FREELANCER.FIND_ONE.replace('%d', String(candidateId))
    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },
  async findAll($this: any) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.FREELANCER.FIND_ALL
    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },
  async find($this: any) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.FREELANCER.FIND
    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },
  async update($this: any, data: any) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.FREELANCER.UPDATE
    return await $this.$axios.put(uri, data, {
      headers: {
        Authorization: token
      }
    })
  }
}
