import Vue from 'vue'
import moment from 'moment'

import shiftService from '@/services/shiftService'
import { arrayUnique } from '@/utils/ArrayUtils'
import { removeEmpty } from '~/utils/ObjectUtils'

import { Location } from '@/store/clients'
import { HireCompany } from '@/store/hireCompany'

export interface UpdateShift {
  id?: number | null
  freelancer?: number | object | null
  start_datetime?: string | null
  end_datetime?: string | null
  pause?: number | null
  pause_paid?: boolean | null
  reason?: string | null
  mail_flex_office?: boolean | null
  mail_department?: boolean | null
  status?: string | null
}

export interface ShiftLog {
  id: number
  edit_by: string
  start_datetime: string | null
  end_datetime: string | null
  pause: number
  pause_paid: boolean
  edit_reason: string

  createdAt: string
  updatedAt: string
}

export interface Freelancer {
  id?: number
  first_name: string
  last_name: string

  hire_company?: HireCompany
  freelancer_datastore?: any
  function?: any
  rate?: any
  vat?: any
}

export interface TotalsId {
  id: number
  location?: Location
  freelancer?: Freelancer
}

export interface Shift {
  id: number
  start_datetime: string
  end_datetime: string
  km: number
  max: number
  pause: number
  pause_paid: boolean
  sleep: number
  note: string
  status: string
  selected?: boolean

  location?: Location
  freelancer?: Freelancer
  totals_id?: TotalsId
  shift_logs?: ShiftLog[]

  createdAt: string
  updatedAt: string
}

const getDefaultState = () => ({
  shifts: [],
  page: 1,
  pageSize: 50,
  isEnd: false,

  apprPage: 1,
  apprPageSize: 50,
  apprIsEnd: false,

  pendPage: 1,
  pendPageSize: 50,
  pendIsEnd: false,

  workedPage: 1,
  workedPageSize: 50,
  workedIsEnd: false
})

export default {
  namespaced: true,
  name: 'shifts',
  state() {
    return getDefaultState()
  },
  mutations: {
    reset(state: any) {
      Object.assign(state, getDefaultState())
    },
    add(state: any, shifts: Shift[]) {
      state.shifts = arrayUnique(state.shifts.concat(shifts), 'id')
      state.shifts.sort(
        (a: Shift, b: Shift) =>
          new Date(a.start_datetime).getTime() -
          new Date(b.start_datetime).getTime()
      )
    },
    addOne(state: any, shift: Shift) {
      state.shifts.unshift(shift)
      state.shifts.sort(
        (a: Shift, b: Shift) =>
          new Date(a.start_datetime).getTime() -
          new Date(b.start_datetime).getTime()
      )
    },
    update(state: any, shift: UpdateShift) {
      const index: number = state.shifts.findIndex(
        (o: Shift) => o.id === shift.id
      )
      if (index) {
        const _shift = { ...state.shifts[index] }
        for (const item in shift) {
          if ((shift as any)[item] !== null) {
            ;(_shift as any)[item] = (shift as any)[item]
          }
        }
        Vue.set(state.shifts, index, _shift)

        state.shifts.sort(
          (a: Shift, b: Shift) =>
            new Date(a.start_datetime).getTime() -
            new Date(b.start_datetime).getTime()
        )
      }
    },
    addToShiftLog(state: any, shiftId: number, shiftLog: ShiftLog) {
      const index: number = state.shifts.findIndex(
        (o: Shift) => o.id === shiftId
      )
      if (!state.shifts[index].shift_logs) {
        state.shifts[index].shift_logs = []
      }
      state.shifts[index].shift_logs.push(shiftLog)
    },
    updateShiftByEdit(state: any, shiftId: number) {
      const shift: Shift | null | undefined = state.shifts.find(
        (o: Shift) => o.id === shiftId
      )

      if (shift) {
        shift.status = 'accepted'
        const edits: ShiftLog[] | undefined = shift.shift_logs
        if (edits && edits.length > 0) {
          const last = edits.at(-1)

          if (last) {
            for (const item in last) {
              if (
                item === 'createdAt' ||
                item === 'updatedAt' ||
                item === 'id' ||
                item === 'edit_by'
              ) {
                continue
              }

              if ((last as any)[item] !== null) {
                ;(shift as any)[item] = (last as any)[item]
              }
            }
          }
        }
      }
    },
    delete(state: any, id: number) {
      state.shifts = state.shifts.filter((shift: Shift) => shift.id !== id)
    },
    incrementPage(state: any, stateName: string) {
      state[stateName]++
    },
    isEnd(state: any, stateName: string) {
      state[stateName] = true
    }
  },
  actions: {
    /** Load a shift by the id */
    async loadShift({ commit }: any, { $this, id }: any) {
      try {
        const response = await shiftService.findOne($this, id)

        if (response.data) {
          commit('addOne', response.data)
        }
      } catch (error) {
        $this.$sentry.captureException(error)
      }
    },
    /** Load all upcoming and pending freelancer shifts */
    async loadShifts({ commit, state }: any, $this: any) {
      try {
        const response = await shiftService.upcoming($this, {
          page: state.page,
          pageSize: state.pageSize
        })

        if (response.data.length > 0) {
          commit('add', response.data)

          if (response.data.length < state.pageSize) {
            commit('isEnd', 'isEnd')
          }
        } else {
          commit('isEnd', 'isEnd')
        }
      } catch (error) {
        $this.$sentry.captureException(error)
      }
    },
    /** Increment the upcomming shift page number for pagination */
    incrementPage({ commit }: any) {
      commit('incrementPage', 'page')
    },
    /** Load all historical approved shifts */
    async loadApprShifts({ commit, state }: any, $this: any) {
      try {
        const response = await shiftService.approved($this, {
          page: state.apprPage,
          pageSize: state.apprPageSize
        })

        if (response.data.length > 0) {
          commit('add', response.data)

          if (response.data.length < state.apprPageSize) {
            commit('isEnd', 'apprIsEnd')
          }
        } else {
          commit('isEnd', 'apprIsEnd')
        }
      } catch (error) {
        $this.$sentry.captureException(error)
      }
    },
    /** Increment the approved shift page number for pagination */
    incrementApprPage({ commit }: any) {
      commit('incrementPage', 'apprPage')
    },
    async loadPendShifts({ commit, state }: any, $this: any) {
      try {
        const response = await shiftService.pending($this, {
          page: state.pendPage,
          pageSize: state.pendPageSize
        })

        if (response.data.length > 0) {
          commit('add', response.data)

          if (response.data.length < state.pendPageSize) {
            commit('isEnd', 'pendIsEnd')
          }
        } else {
          commit('isEnd', 'pendIsEnd')
        }
      } catch (error) {
        $this.$sentry.captureException(error)
      }
    },
    /** Increment the pending shift page number for pagination */
    incrementPendPage({ commit }: any) {
      commit('incrementPage', 'pendPage')
    },
    async loadWorkedShifts({ commit, state }: any, $this: any) {
      try {
        const response = await shiftService.worked($this, {
          page: state.workedPage,
          pageSize: state.workedPageSize
        })

        if (response.data.length > 0) {
          commit('add', response.data)

          if (response.data.length < state.workedPageSize) {
            commit('isEnd', 'workedIsEnd')
          }
        } else {
          commit('isEnd', 'workedIsEnd')
        }
      } catch (error) {
        $this.$sentry.captureException(error)
      }
    },
    /** Increment the pending shift page number for pagination */
    incrementWorkedPage({ commit }: any) {
      commit('incrementPage', 'workedPage')
    },
    /** Create shift on the server and add it to the store */
    async create({ commit }: any, { $this, shift }: any) {
      try {
        const response = await shiftService.create($this, shift)

        if (response.data) {
          commit('addOne', response.data as Shift)
        }
      } catch (error: any) {
        $this.$sentry.captureException(error)
        throw new Error(error)
      }
    },
    /** Update shift on the server and update the store */
    async update({ commit }: any, { $this, shift }: any) {
      try {
        const values = removeEmpty(shift as UpdateShift)
        const response = await shiftService.update($this, values)

        if (response.data) {
          const shift = response.data
          commit('update', {
            ...values,
            start_datetime: shift.start_datetime,
            end_datetime: shift.end_datetime
          })
        }
      } catch (error: any) {
        $this.$sentry.captureException(error)
        throw new Error(error)
      }
    },
    /** Update shift on the server and update the store */
    async approve({ commit }: any, { $this, shift }: any) {
      try {
        const values = removeEmpty(shift as UpdateShift)
        const response = await shiftService.approve($this, values)

        if (response.data) {
          commit('update', {
            ...values,
            status: 'pending_location'
          })
        }
      } catch (error: any) {
        $this.$sentry.captureException(error)
        throw new Error(error)
      }
    },
    /** Approve shift changes on the server and update the store */
    async approveChanges({ commit }: any, { $this, shiftId }: any) {
      try {
        const response = await shiftService.approveChanges($this, shiftId)

        if (response.data) {
          commit('updateShiftByEdit', shiftId)
        }
      } catch (error: any) {
        $this.$sentry.captureException(error)
        throw new Error(error)
      }
    },
    /** Delete shift on the server and delete from the store */
    async delete({ commit }: any, { $this, id }: any) {
      try {
        const response = await shiftService.delete($this, id)

        if (response.data) {
          commit('delete', id)
        }
      } catch (error: any) {
        $this.$sentry.captureException(error)
        throw new Error(error)
      }
    },
    /** Find all shifts */
    async findAll({ commit }: any, { $this, week, year }: any) {
      try {
        const response = await shiftService.findAll($this, week, year)

        if (response.data) {
          commit('add', response.data)
        }
      } catch (error: any) {
        $this.$sentry.captureException(error)
        throw new Error(error)
      }
    }
  },
  getters: {
    shifts: (state: any) => {
      return state.shifts
    },
    upcoming: (state: any) => {
      return state.shifts.filter((shift: Shift) =>
        ['planned', 'pending_freelancer'].includes(shift.status)
      )
    },
    pending: (state: any) => {
      return state.shifts.filter((shift: Shift) =>
        ['pending_location', 'pending_freelancer', 'rejected'].includes(
          shift.status
        )
      )
    },
    approved: (state: any) => {
      return state.shifts.filter(
        (shift: Shift) =>
          shift.status === 'accepted' || shift.status === 'invoiced'
      )
    },
    worked: (state: any) => {
      return state.shifts.filter((shift: Shift) =>
        [
          'pending_location',
          'pending_freelancer',
          'rejected',
          'invoiced',
          'accepted'
        ].includes(shift.status)
      )
    },
    today: (state: any) => {
      return state.shifts.find((shift: Shift) =>
        moment.utc(shift.start_datetime).isSame(new Date(), 'day')
      )
    },
    byId: (state: any) => (shiftId: number | string) => {
      shiftId = Number(shiftId)
      return state.shifts.find((shift: Shift) => shift.id === shiftId)
    }
  }
}
