export const ConfettiComponent = () => import('../../components/ConfettiComponent.vue' /* webpackChunkName: "components/confetti-component" */).then(c => wrapFunctional(c.default || c))
export const Countdown = () => import('../../components/Countdown.vue' /* webpackChunkName: "components/countdown" */).then(c => wrapFunctional(c.default || c))
export const DatePicker = () => import('../../components/DatePicker.vue' /* webpackChunkName: "components/date-picker" */).then(c => wrapFunctional(c.default || c))
export const ImageCropper = () => import('../../components/ImageCropper.vue' /* webpackChunkName: "components/image-cropper" */).then(c => wrapFunctional(c.default || c))
export const Navbar = () => import('../../components/Navbar.vue' /* webpackChunkName: "components/navbar" */).then(c => wrapFunctional(c.default || c))
export const NotificationHandler = () => import('../../components/NotificationHandler.vue' /* webpackChunkName: "components/notification-handler" */).then(c => wrapFunctional(c.default || c))
export const PullToRefresh = () => import('../../components/PullToRefresh.vue' /* webpackChunkName: "components/pull-to-refresh" */).then(c => wrapFunctional(c.default || c))
export const SnackbarComponent = () => import('../../components/SnackbarComponent.vue' /* webpackChunkName: "components/snackbar-component" */).then(c => wrapFunctional(c.default || c))
export const TextDivider = () => import('../../components/TextDivider.vue' /* webpackChunkName: "components/text-divider" */).then(c => wrapFunctional(c.default || c))
export const AuthRegisterForm = () => import('../../components/auth/RegisterForm.vue' /* webpackChunkName: "components/auth-register-form" */).then(c => wrapFunctional(c.default || c))
export const CalendarShiftPlanner = () => import('../../components/calendar/ShiftPlanner.vue' /* webpackChunkName: "components/calendar-shift-planner" */).then(c => wrapFunctional(c.default || c))
export const CardsContractCard = () => import('../../components/cards/ContractCard.vue' /* webpackChunkName: "components/cards-contract-card" */).then(c => wrapFunctional(c.default || c))
export const CardsContractDetails = () => import('../../components/cards/ContractDetails.vue' /* webpackChunkName: "components/cards-contract-details" */).then(c => wrapFunctional(c.default || c))
export const CardsFreelancerShiftCard = () => import('../../components/cards/FreelancerShiftCard.vue' /* webpackChunkName: "components/cards-freelancer-shift-card" */).then(c => wrapFunctional(c.default || c))
export const CardsInvoiceCard = () => import('../../components/cards/InvoiceCard.vue' /* webpackChunkName: "components/cards-invoice-card" */).then(c => wrapFunctional(c.default || c))
export const CardsJobDetailsCard = () => import('../../components/cards/JobDetailsCard.vue' /* webpackChunkName: "components/cards-job-details-card" */).then(c => wrapFunctional(c.default || c))
export const CardsShiftCard = () => import('../../components/cards/ShiftCard.vue' /* webpackChunkName: "components/cards-shift-card" */).then(c => wrapFunctional(c.default || c))
export const CardsShiftCreateCard = () => import('../../components/cards/ShiftCreateCard.vue' /* webpackChunkName: "components/cards-shift-create-card" */).then(c => wrapFunctional(c.default || c))
export const CardsShiftDetailsCard = () => import('../../components/cards/ShiftDetailsCard.vue' /* webpackChunkName: "components/cards-shift-details-card" */).then(c => wrapFunctional(c.default || c))
export const CardsShiftRequestCard = () => import('../../components/cards/ShiftRequestCard.vue' /* webpackChunkName: "components/cards-shift-request-card" */).then(c => wrapFunctional(c.default || c))
export const CardsSimpleDataCard = () => import('../../components/cards/SimpleDataCard.vue' /* webpackChunkName: "components/cards-simple-data-card" */).then(c => wrapFunctional(c.default || c))
export const CardsSimpleDocumentCard = () => import('../../components/cards/SimpleDocumentCard.vue' /* webpackChunkName: "components/cards-simple-document-card" */).then(c => wrapFunctional(c.default || c))
export const CardsSimpleFreelancerCard = () => import('../../components/cards/SimpleFreelancerCard.vue' /* webpackChunkName: "components/cards-simple-freelancer-card" */).then(c => wrapFunctional(c.default || c))
export const ContractsBottomNavigation = () => import('../../components/contracts/BottomNavigation.vue' /* webpackChunkName: "components/contracts-bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const ContractsContractCardIterator = () => import('../../components/contracts/ContractCardIterator.vue' /* webpackChunkName: "components/contracts-contract-card-iterator" */).then(c => wrapFunctional(c.default || c))
export const ContractsContractTable = () => import('../../components/contracts/ContractTable.vue' /* webpackChunkName: "components/contracts-contract-table" */).then(c => wrapFunctional(c.default || c))
export const ContractsSimpleContractTable = () => import('../../components/contracts/SimpleContractTable.vue' /* webpackChunkName: "components/contracts-simple-contract-table" */).then(c => wrapFunctional(c.default || c))
export const DashboardUserDashboard = () => import('../../components/dashboard/UserDashboard.vue' /* webpackChunkName: "components/dashboard-user-dashboard" */).then(c => wrapFunctional(c.default || c))
export const DocumentsCreateDocument = () => import('../../components/documents/CreateDocument.vue' /* webpackChunkName: "components/documents-create-document" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDocumentMultiPreviewer = () => import('../../components/documents/DocumentMultiPreviewer.vue' /* webpackChunkName: "components/documents-document-multi-previewer" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDocumentPreview = () => import('../../components/documents/DocumentPreview.vue' /* webpackChunkName: "components/documents-document-preview" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDocumentPreviewer = () => import('../../components/documents/DocumentPreviewer.vue' /* webpackChunkName: "components/documents-document-previewer" */).then(c => wrapFunctional(c.default || c))
export const DocumentsDocumentTable = () => import('../../components/documents/DocumentTable.vue' /* webpackChunkName: "components/documents-document-table" */).then(c => wrapFunctional(c.default || c))
export const Documents = () => import('../../components/documents/Documents.vue' /* webpackChunkName: "components/documents" */).then(c => wrapFunctional(c.default || c))
export const DocumentsEmrexRequest = () => import('../../components/documents/EmrexRequest.vue' /* webpackChunkName: "components/documents-emrex-request" */).then(c => wrapFunctional(c.default || c))
export const DocumentsImportDocument = () => import('../../components/documents/ImportDocument.vue' /* webpackChunkName: "components/documents-import-document" */).then(c => wrapFunctional(c.default || c))
export const FilterGenericFilterComponent = () => import('../../components/filter/GenericFilterComponent.vue' /* webpackChunkName: "components/filter-generic-filter-component" */).then(c => wrapFunctional(c.default || c))
export const FilterGenericFilterWrapperComponent = () => import('../../components/filter/GenericFilterWrapperComponent.vue' /* webpackChunkName: "components/filter-generic-filter-wrapper-component" */).then(c => wrapFunctional(c.default || c))
export const FormCustomAddressField = () => import('../../components/form/CustomAddressField.vue' /* webpackChunkName: "components/form-custom-address-field" */).then(c => wrapFunctional(c.default || c))
export const FormCustomCountrySelect = () => import('../../components/form/CustomCountrySelect.vue' /* webpackChunkName: "components/form-custom-country-select" */).then(c => wrapFunctional(c.default || c))
export const FormCustomPhoneField = () => import('../../components/form/CustomPhoneField.vue' /* webpackChunkName: "components/form-custom-phone-field" */).then(c => wrapFunctional(c.default || c))
export const FormCustomPostalField = () => import('../../components/form/CustomPostalField.vue' /* webpackChunkName: "components/form-custom-postal-field" */).then(c => wrapFunctional(c.default || c))
export const FormCustomRichTextarea = () => import('../../components/form/CustomRichTextarea.vue' /* webpackChunkName: "components/form-custom-rich-textarea" */).then(c => wrapFunctional(c.default || c))
export const FormCustomSelect = () => import('../../components/form/CustomSelect.vue' /* webpackChunkName: "components/form-custom-select" */).then(c => wrapFunctional(c.default || c))
export const FormCustomSimpleDataCard = () => import('../../components/form/CustomSimpleDataCard.vue' /* webpackChunkName: "components/form-custom-simple-data-card" */).then(c => wrapFunctional(c.default || c))
export const FormCustomTags = () => import('../../components/form/CustomTags.vue' /* webpackChunkName: "components/form-custom-tags" */).then(c => wrapFunctional(c.default || c))
export const FormCustomTextarea = () => import('../../components/form/CustomTextarea.vue' /* webpackChunkName: "components/form-custom-textarea" */).then(c => wrapFunctional(c.default || c))
export const FormCustomTextfield = () => import('../../components/form/CustomTextfield.vue' /* webpackChunkName: "components/form-custom-textfield" */).then(c => wrapFunctional(c.default || c))
export const FormCustomTimePickerField = () => import('../../components/form/CustomTimePickerField.vue' /* webpackChunkName: "components/form-custom-time-picker-field" */).then(c => wrapFunctional(c.default || c))
export const FormCustomToggleBox = () => import('../../components/form/CustomToggleBox.vue' /* webpackChunkName: "components/form-custom-toggle-box" */).then(c => wrapFunctional(c.default || c))
export const FormCustomWeekPlanner = () => import('../../components/form/CustomWeekPlanner.vue' /* webpackChunkName: "components/form-custom-week-planner" */).then(c => wrapFunctional(c.default || c))
export const FormLocationSelect = () => import('../../components/form/LocationSelect.vue' /* webpackChunkName: "components/form-location-select" */).then(c => wrapFunctional(c.default || c))
export const FormUploadCard = () => import('../../components/form/UploadCard.vue' /* webpackChunkName: "components/form-upload-card" */).then(c => wrapFunctional(c.default || c))
export const FreelancerActionBar = () => import('../../components/freelancer/ActionBar.vue' /* webpackChunkName: "components/freelancer-action-bar" */).then(c => wrapFunctional(c.default || c))
export const FreelancerButtonsDialog = () => import('../../components/freelancer/ButtonsDialog.vue' /* webpackChunkName: "components/freelancer-buttons-dialog" */).then(c => wrapFunctional(c.default || c))
export const FreelancerCandidateSwitcher = () => import('../../components/freelancer/CandidateSwitcher.vue' /* webpackChunkName: "components/freelancer-candidate-switcher" */).then(c => wrapFunctional(c.default || c))
export const FreelancerContractPreviewer = () => import('../../components/freelancer/ContractPreviewer.vue' /* webpackChunkName: "components/freelancer-contract-previewer" */).then(c => wrapFunctional(c.default || c))
export const FreelancerDocumentsLoader = () => import('../../components/freelancer/DocumentsLoader.vue' /* webpackChunkName: "components/freelancer-documents-loader" */).then(c => wrapFunctional(c.default || c))
export const FreelancerProfileData = () => import('../../components/freelancer/FreelancerProfileData.vue' /* webpackChunkName: "components/freelancer-profile-data" */).then(c => wrapFunctional(c.default || c))
export const FreelancerMotivationPreviewer = () => import('../../components/freelancer/MotivationPreviewer.vue' /* webpackChunkName: "components/freelancer-motivation-previewer" */).then(c => wrapFunctional(c.default || c))
export const FreelancerProgressBar = () => import('../../components/freelancer/ProgressBar.vue' /* webpackChunkName: "components/freelancer-progress-bar" */).then(c => wrapFunctional(c.default || c))
export const FreelancerToolbarDialog = () => import('../../components/freelancer/ToolbarDialog.vue' /* webpackChunkName: "components/freelancer-toolbar-dialog" */).then(c => wrapFunctional(c.default || c))
export const InvoiceBottomNavigation = () => import('../../components/invoice/InvoiceBottomNavigation.vue' /* webpackChunkName: "components/invoice-bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const InvoiceCardTable = () => import('../../components/invoice/InvoiceCardTable.vue' /* webpackChunkName: "components/invoice-card-table" */).then(c => wrapFunctional(c.default || c))
export const InvoiceTable = () => import('../../components/invoice/InvoiceTable.vue' /* webpackChunkName: "components/invoice-table" */).then(c => wrapFunctional(c.default || c))
export const InvoiceTableTypeFilter = () => import('../../components/invoice/TableTypeFilter.vue' /* webpackChunkName: "components/invoice-table-type-filter" */).then(c => wrapFunctional(c.default || c))
export const JobsBottomNavigationJobs = () => import('../../components/jobs/BottomNavigationJobs.vue' /* webpackChunkName: "components/jobs-bottom-navigation-jobs" */).then(c => wrapFunctional(c.default || c))
export const JobsShiftRequestCardTable = () => import('../../components/jobs/ShiftRequestCardTable.vue' /* webpackChunkName: "components/jobs-shift-request-card-table" */).then(c => wrapFunctional(c.default || c))
export const MapDefaultMap = () => import('../../components/map/DefaultMap.vue' /* webpackChunkName: "components/map-default-map" */).then(c => wrapFunctional(c.default || c))
export const MiscTextRenderer = () => import('../../components/misc/TextRenderer.vue' /* webpackChunkName: "components/misc-text-renderer" */).then(c => wrapFunctional(c.default || c))
export const ModalsActionButtonDialog = () => import('../../components/modals/ActionButtonDialog.vue' /* webpackChunkName: "components/modals-action-button-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalsConfirmDialog = () => import('../../components/modals/ConfirmDialog.vue' /* webpackChunkName: "components/modals-confirm-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalsCreateShiftDialog = () => import('../../components/modals/CreateShiftDialog.vue' /* webpackChunkName: "components/modals-create-shift-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalsFreelancerProfileDialog = () => import('../../components/modals/FreelancerProfileDialog.vue' /* webpackChunkName: "components/modals-freelancer-profile-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalsRejectShiftDialog = () => import('../../components/modals/RejectShiftDialog.vue' /* webpackChunkName: "components/modals-reject-shift-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalsUpdateDialog = () => import('../../components/modals/UpdateDialog.vue' /* webpackChunkName: "components/modals-update-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalsVoidedDialog = () => import('../../components/modals/VoidedDialog.vue' /* webpackChunkName: "components/modals-voided-dialog" */).then(c => wrapFunctional(c.default || c))
export const ModalsWeekSelectorDialog = () => import('../../components/modals/WeekSelectorDialog.vue' /* webpackChunkName: "components/modals-week-selector-dialog" */).then(c => wrapFunctional(c.default || c))
export const OnboardingCertificateStep = () => import('../../components/onboarding/CertificateStep.vue' /* webpackChunkName: "components/onboarding-certificate-step" */).then(c => wrapFunctional(c.default || c))
export const OnboardingDegreeStep = () => import('../../components/onboarding/DegreeStep.vue' /* webpackChunkName: "components/onboarding-degree-step" */).then(c => wrapFunctional(c.default || c))
export const OnboardingIntroductionStep = () => import('../../components/onboarding/IntroductionStep.vue' /* webpackChunkName: "components/onboarding-introduction-step" */).then(c => wrapFunctional(c.default || c))
export const OnboardingPreferenceStep = () => import('../../components/onboarding/PreferenceStep.vue' /* webpackChunkName: "components/onboarding-preference-step" */).then(c => wrapFunctional(c.default || c))
export const OnboardingVerificationStep = () => import('../../components/onboarding/VerificationStep.vue' /* webpackChunkName: "components/onboarding-verification-step" */).then(c => wrapFunctional(c.default || c))
export const PlanningApproveBottomNavigation = () => import('../../components/planning/ApproveBottomNavigation.vue' /* webpackChunkName: "components/planning-approve-bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const PlanningBottomNavigation = () => import('../../components/planning/BottomNavigation.vue' /* webpackChunkName: "components/planning-bottom-navigation" */).then(c => wrapFunctional(c.default || c))
export const PlanningCardTable = () => import('../../components/planning/PlanningCardTable.vue' /* webpackChunkName: "components/planning-card-table" */).then(c => wrapFunctional(c.default || c))
export const SettingsNotifications = () => import('../../components/settings/notifications.vue' /* webpackChunkName: "components/settings-notifications" */).then(c => wrapFunctional(c.default || c))
export const TablesClientShiftRequestTable = () => import('../../components/tables/ClientShiftRequestTable.vue' /* webpackChunkName: "components/tables-client-shift-request-table" */).then(c => wrapFunctional(c.default || c))
export const AdministratorDashboardAdminDashboard = () => import('../../components/administrator/dashboard/AdminDashboard.vue' /* webpackChunkName: "components/administrator-dashboard-admin-dashboard" */).then(c => wrapFunctional(c.default || c))
export const AdministratorDocumentsDocumentTodoOverview = () => import('../../components/administrator/documents/DocumentTodoOverview.vue' /* webpackChunkName: "components/administrator-documents-document-todo-overview" */).then(c => wrapFunctional(c.default || c))
export const OnboardingClientStep1 = () => import('../../components/onboarding/client/ClientStep1.vue' /* webpackChunkName: "components/onboarding-client-step1" */).then(c => wrapFunctional(c.default || c))
export const OnboardingClientStep2 = () => import('../../components/onboarding/client/ClientStep2.vue' /* webpackChunkName: "components/onboarding-client-step2" */).then(c => wrapFunctional(c.default || c))
export const OnboardingClientStep3 = () => import('../../components/onboarding/client/ClientStep3.vue' /* webpackChunkName: "components/onboarding-client-step3" */).then(c => wrapFunctional(c.default || c))
export const OnboardingClientStep4 = () => import('../../components/onboarding/client/ClientStep4.vue' /* webpackChunkName: "components/onboarding-client-step4" */).then(c => wrapFunctional(c.default || c))
export const AdministratorFreelancersFreelancerOverview = () => import('../../components/administrator/freelancers/FreelancerOverview.vue' /* webpackChunkName: "components/administrator-freelancers-freelancer-overview" */).then(c => wrapFunctional(c.default || c))
export const AdministratorFreelancersFreelancerTable = () => import('../../components/administrator/freelancers/FreelancerTable.vue' /* webpackChunkName: "components/administrator-freelancers-freelancer-table" */).then(c => wrapFunctional(c.default || c))
export const OnboardingClientFormBlockRateForm = () => import('../../components/onboarding/client/form/BlockRateForm.vue' /* webpackChunkName: "components/onboarding-client-form-block-rate-form" */).then(c => wrapFunctional(c.default || c))
export const OnboardingClientFormContractClientForm = () => import('../../components/onboarding/client/form/ContractClientForm.vue' /* webpackChunkName: "components/onboarding-client-form-contract-client-form" */).then(c => wrapFunctional(c.default || c))
export const OnboardingClientFormDayTypeSelector = () => import('../../components/onboarding/client/form/DayTypeSelector.vue' /* webpackChunkName: "components/onboarding-client-form-day-type-selector" */).then(c => wrapFunctional(c.default || c))
export const OnboardingClientFormKmForm = () => import('../../components/onboarding/client/form/KmForm.vue' /* webpackChunkName: "components/onboarding-client-form-km-form" */).then(c => wrapFunctional(c.default || c))
export const OnboardingLocationDetailStep1 = () => import('../../components/onboarding/location/LocationDetailStep1.vue' /* webpackChunkName: "components/onboarding-location-detail-step1" */).then(c => wrapFunctional(c.default || c))
export const OnboardingLocationStep2 = () => import('../../components/onboarding/location/LocationStep2.vue' /* webpackChunkName: "components/onboarding-location-step2" */).then(c => wrapFunctional(c.default || c))
export const OnboardingLocationStep3 = () => import('../../components/onboarding/location/LocationStep3.vue' /* webpackChunkName: "components/onboarding-location-step3" */).then(c => wrapFunctional(c.default || c))
export const OnboardingLocationStep4 = () => import('../../components/onboarding/location/LocationStep4.vue' /* webpackChunkName: "components/onboarding-location-step4" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
