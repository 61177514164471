import * as qs from 'qs'
import { ApiConfig } from '../config.js'

export default {
  async findAll($this: any) {
    const token = $this.$auth.strategy.token

    const uri = ApiConfig.CONTRACT.FIND_ALL
    const query = qs.stringify(
      {
        pagination: {
          page: 1,
          pageSize: 100
        },
        populate: {
          functions: {
            fields: ['title']
          },
          contract: true,
          signed_contract: true,
          shift_request: {
            populate: {
              location: {
                fields: ['name']
              },
              block_rates: true
            }
          },
          freelancer: {
            fields: ['first_name', 'last_name'],
            populate: {
              user: {
                fields: ['firstName', 'lastName'],
                populate: {
                  avatar: true
                }
              }
            }
          }
        },
        sort: ['shift_request.start_date:asc'],
        locale: $this.$i18n ? $this.$i18n.locale : 'nl'
      },
      { encodeValuesOnly: true }
    )

    return await $this.$axios.get(`${uri}?${query}`, {
      headers: {
        Authorization: token
      }
    })
  },
  findById($this: any, id: any) {
    const token = $this.$auth.strategy.token.get()
    const uri = ApiConfig.CONTRACT.FIND_BY_ID.replace('%d', String(id))

    return $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },
  async findRestricted($this: any) {
    const token = $this.$auth.strategy.token

    const uri = ApiConfig.CONTRACT.FIND_RESTRICTED
    const query = qs.stringify(
      {
        locale: $this.$i18n ? $this.$i18n.locale : 'nl'
      },
      { encodeValuesOnly: true }
    )

    return await $this.$axios.get(`${uri}?${query}`, {
      headers: {
        Authorization: token
      }
    })
  },
  async create($this: any, data: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.post(ApiConfig.CONTRACT.CREATE, data, {
      headers: {
        Authorization: token
      }
    })
  },
  async getContract($this: any, id: number) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.CONTRACT.SHOW

    return await $this.$axios.post(
      uri,
      { id },
      {
        responseType: 'blob',
        headers: {
          Authorization: token
        }
      }
    )
  },
  async sign($this: any, data: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.post(ApiConfig.CONTRACT.SIGN, data, {
      headers: {
        Authorization: token
      }
    })
  },
  async getSigningUrl($this: any, data: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.post(ApiConfig.CONTRACT.GET_SIGNING_URL, data, {
      headers: {
        Authorization: token
      }
    })
  },
  async checkContractValidity($this: any, data: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.post(ApiConfig.CONTRACT.CHECK_CONTRACT, data, {
      headers: {
        Authorization: token
      }
    })
  },
  async connectShiftRequest($this: any, data: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.post(
      ApiConfig.CONTRACT.CONNECT_SHIFT_REQUEST,
      data,
      {
        headers: {
          Authorization: token
        }
      }
    )
  },
  async voidContract($this: any, data: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.post(ApiConfig.CONTRACT.VOIDED, data, {
      headers: {
        Authorization: token
      }
    })
  },
  async delete($this: any, data: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.post(ApiConfig.CONTRACT.DELETE, data, {
      headers: {
        Authorization: token
      }
    })
  }
}
