
import Vue, { type PropType } from 'vue'

export default Vue.extend({
  name: 'PullToRefresh',

  props: {
    container: {
      type: null as unknown as PropType<HTMLElement | null>,
      required: true
    }
  },
  data: () => ({
    canRelease: false,
    startY: 0,
    isPulling: false,
    isRefreshing: false
  }),
  methods: {
    async init() {
      await this.$nextTick()

      if (!this.container) return

      this.container.addEventListener('touchstart', this.startPull, {
        passive: false
      })
      this.container.addEventListener('touchmove', this.pulling, {
        passive: false
      })
      for (const event of [
        'mouseup',
        'mouseleave',
        'touchend',
        'touchcancel'
      ]) {
        this.container.addEventListener(event, this.endPull)
      }
    },

    startPull(e: any) {
      if (!this.container) return

      if (this.container.scrollTop > 0 || this.isRefreshing) return

      this.startY = (e.clientY ? e.clientY : e.touches[0].clientY) || 0
    },

    pulling(e: any) {
      if (!this.container) return

      if (!this.startY || this.isRefreshing) return

      const y = (e.clientY ? e.clientY : e.touches[0].clientY) || 0
      const height = y - this.startY

      if (height < 0) {
        this.startY = 0
        this.container.style.setProperty('overflow', '')
        this.isPulling = false
        return
      }
      if (height) e.preventDefault()
      if (height < 10) return

      this.container.style.setProperty('overflow', 'hidden', 'important')

      this.canRelease = height > 40
      this.isPulling = true
      ;(this.$refs.el as any).style.height = `${
        this.canRelease && height > 0 ? Math.sqrt(height * 40) : height
      }px`
    },

    endPull() {
      if (!this.container) return

      if (!this.startY || this.isRefreshing) return
      ;(this.$refs.el as any).style.height = `${this.canRelease ? 40 : 0}px`
      if (this.canRelease) {
        this.$emit('refresh')
        this.isRefreshing = true
      }
      this.container.style.setProperty('overflow', '')
      this.startY = 0
    },

    refreshSuccess() {
      ;(this.$refs.el as any).style.height = 0
      this.isPulling = false
    },

    handleTransitionEnd() {
      if (!this.isPulling) {
        this.canRelease = false
        this.isRefreshing = false
      }
    },

    stopPull() {
      if (!this.container) return

      this.container.style.setProperty('overflow', '')
      this.startY = 0
      this.canRelease = false
      this.isPulling = false
      this.isRefreshing = false
      ;(this.$refs.el as any).style.height = 0
    }
  }
})
