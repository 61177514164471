import * as qs from 'qs'

import { ApiConfig } from '../config.js'
import { Pagination } from '~/types/pagination'

export default {
  async find($this: any, pagination: Pagination | null = null) {
    const token = $this.$auth.strategy.token
    let uri = ApiConfig.SHIFT_REQUESTS.FIND
    if (pagination) {
      const query = qs.stringify(
        {
          pagination: {
            page: pagination.page || 1,
            pageSize: pagination.pageSize || 10
          },
          locale: $this.$i18n ? $this.$i18n.locale : 'nl'
        },
        { encodeValuesOnly: true }
      )
      uri = `${uri}?${query}`
    }

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },

  async findByClient($this: any) {
    const token = $this.$auth.strategy.token

    return await $this.$axios.get(ApiConfig.SHIFT_REQUESTS.FIND_BY_CLIENT, {
      headers: {
        Authorization: token
      }
    })
  },

  async create($this: any, data: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.post(ApiConfig.SHIFT_REQUESTS.CREATE, data, {
      headers: {
        Authorization: token
      }
    })
  },
  async update($this: any, id: any, data: any) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.SHIFT_REQUESTS.UPDATE.replace('%d', String(id))

    return await $this.$axios.put(uri, data, {
      headers: {
        Authorization: token
      }
    })
  },

  async updateCandidate($this: any, id: any, data: any) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.SHIFT_REQUESTS.UPDATE_CANDIDATE.replace(
      '%d',
      String(id)
    )

    return await $this.$axios.put(uri, data, {
      headers: {
        Authorization: token
      }
    })
  },

  async archive($this: any, id: any) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.SHIFT_REQUESTS.ARCHIVE.replace('%d', String(id))

    return await $this.$axios.put(uri, null, {
      headers: {
        Authorization: token
      }
    })
  },

  async apply(
    $this: any,
    id: any,
    motivation: string,
    desiredRate: number,
    available: boolean
  ) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.put(
      ApiConfig.SHIFT_REQUESTS.APPLY,
      { id, motivation, desiredRate, available },
      {
        headers: {
          Authorization: token
        }
      }
    )
  },

  async toggle($this: any, data: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.put(ApiConfig.SHIFT_REQUESTS.TOGGLE, data, {
      headers: {
        Authorization: token
      }
    })
  },

  getFile($this: any, id: any) {
    const token = $this.$auth.strategy.token
    return $this.$axios.get(
      ApiConfig.SHIFT_REQUESTS.GET_FILE.replace('%d', id),
      {
        headers: {
          Authorization: token
        },
        responseType: 'blob'
      }
    )
  }
}
