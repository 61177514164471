import { ApiConfig } from '../config.js'

export default {
  async get($this) {
    const token = $this.$auth.strategy.token
    let uri = null
    if ($this.$auth.user.role.type === 'admin') {
      uri = ApiConfig.INVOICES.GET_ALL
    } else if ($this.$auth.user.role.type === 'client') {
      uri = ApiConfig.INVOICES.GET_CLIENT
    } else {
      uri = ApiConfig.INVOICES.GET
    }

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },
  async download($this, invoice) {
    const token = $this.$auth.strategy.token
    const uri =
      invoice && invoice.freelancer_id
        ? ApiConfig.INVOICES.DOWNLOAD +
          `/${invoice.id}/${invoice.type}/${invoice.freelancer_id}`
        : ApiConfig.INVOICES.DOWNLOAD + `/${invoice.id}/${invoice.type}`

    return await $this.$axios.get(uri, {
      responseType: 'blob',
      headers: {
        Authorization: token
      }
    })
  }
}
