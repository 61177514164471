import { type GetterTree, type ActionTree, type MutationTree } from 'vuex'
import shiftRequestService from '~/services/shiftRequestService'

export interface ShiftRequest {
  id: number
  attributes: {
    type: 'single' | 'long-term'
    location: any
    functions: any[]
    client: any
    start_date: Date
    end_date: Date
    start_time: string
    end_time: string
    pause: number
    pause_paid: boolean
    weekly_hours: number
    description: string
    urgent: boolean
    week_planner: any
    status: 'open' | 'pending' | 'solved' | 'failed'
    active: boolean
    block_rates: any
    shift_request_candidates: any[]
    publishedAt: Date | null
    km_compensation: number
    max_km: number
    contract: any
    location_quantity: string
  }
}

const getDefaultState = () => ({
  page: 1,
  pageSize: 50,
  isEnd: false,
  shiftRequests: [] as ShiftRequest[]
})

export const state = getDefaultState

export type ShiftRequestState = ReturnType<typeof state>

export const mutations: MutationTree<ShiftRequestState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  archive(state, id: number) {
    const request: number = state.shiftRequests.findIndex(
      (o: ShiftRequest) => o.id === id
    )
    if (request > -1) {
      state.shiftRequests.splice(request, 1)
    }
  },
  updateCandidate(state, data) {
    const request: ShiftRequest | null | undefined = state.shiftRequests.find(
      (o: ShiftRequest) => o.id === data.shift_request_id
    )
    if (request) {
      const candidate: any | null | undefined =
        request.attributes.shift_request_candidates.find(
          (o: any) => o.id === data.id
        )

      if (candidate) {
        candidate.status = data.status
      }
    }
  },
  update(state, { id, attributes }) {
    const request: ShiftRequest | null | undefined = state.shiftRequests.find(
      (o: ShiftRequest) => o.id === id
    )

    if (request) {
      request.attributes = { ...request.attributes, ...attributes }
    }
  },
  set(state, shiftRequests: ShiftRequest[]) {
    state.shiftRequests = shiftRequests
  },
  add(state, shiftRequest: ShiftRequest) {
    state.shiftRequests.push(shiftRequest)
  },
  add_concat(state, shiftRequests: ShiftRequest[]) {
    state.shiftRequests = state.shiftRequests.concat(shiftRequests)
  },
  incrementPage(state: any) {
    state.page++
  },
  isEnd(state, value: boolean) {
    state.isEnd = value
  },
  apply(state, { id, freelancer }) {
    const request: ShiftRequest | null | undefined = state.shiftRequests.find(
      (o: ShiftRequest) => o.id === id
    )
    if (request) {
      request.attributes.status = 'pending'
      request.attributes.shift_request_candidates.push({
        freelancer
      })
    }
  },
  toggle(state, id: number) {
    const request: ShiftRequest | null | undefined = state.shiftRequests.find(
      (o: ShiftRequest) => o.id === id
    )
    if (request) {
      request.attributes.active = !request.attributes.active
    }
  }
}

export const actions: ActionTree<ShiftRequestState, ShiftRequestState> = {
  async archive({ commit }, { $this, data }) {
    try {
      const response = await shiftRequestService.archive($this, data.id)
      commit('archive', data.id)
      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },
  async updateCandidate({ commit }, { $this, data }) {
    try {
      commit('updateCandidate', data)
      const response = await shiftRequestService.updateCandidate(
        $this,
        data.id,
        data
      )
      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },
  async create({ commit }, _shiftRequest) {
    try {
      const response = await shiftRequestService.create(this, _shiftRequest)

      const { shiftRequest } = response.data
      commit('add', {
        id: shiftRequest.id,
        attributes: {
          ...shiftRequest
        }
      })

      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },
  async update({ commit, rootState }, { $this, data }) {
    try {
      const response = await shiftRequestService.update(
        $this,
        data.id,
        data.attributes
      )

      const alteredData = {
        id: data.id,
        attributes: {
          ...data.attributes
        }
      }

      if (data.attributes.functions) {
        alteredData.attributes.functions = data.attributes.functions.map(
          (id: any) => {
            const rState = (rootState as any).functions.functions.find(
              (o: any) => o.id === id
            )

            return { id: rState.id, ...rState.attributes }
          }
        )
      }

      commit('update', alteredData)
      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },

  async loadShifts({ commit, state }: any, $this: any) {
    try {
      const response = await shiftRequestService.find($this, {
        page: state.page,
        pageSize: state.pageSize
      })

      if (response.data.data.length > 0) {
        commit('add_concat', response.data.data)

        if (response.data.data.length < state.pageSize) {
          commit('isEnd', true)
        }
      } else {
        commit('isEnd', true)
      }
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },

  async loadClientShifts({ commit }: any, $this: any) {
    try {
      const response = await shiftRequestService.findByClient($this)

      if (response.data.data.length > 0) {
        commit('add_concat', response.data.data)
      }
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },

  async toggle({ commit }: any, { $this, data }: any) {
    try {
      const response = await shiftRequestService.toggle($this, data)
      commit('toggle', data.id)
      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },

  /** Increment the shift requests page number for pagination */
  incrementPage({ commit }: any) {
    commit('incrementPage')
  },

  /** Apply the freelancer for the job */
  async apply(
    { commit },
    { $this, id, motivation, freelancer, desiredRate, available }: any
  ) {
    try {
      const response = await shiftRequestService.apply(
        $this,
        id,
        motivation,
        desiredRate,
        available
      )
      commit('apply', { id, freelancer })
      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  }
}

export const getters: GetterTree<ShiftRequestState, ShiftRequestState> = {
  reacted: (_state: any, getters: any) => (freelancerId: number | string) => {
    const shiftRequests = getters.shiftRequests
    return shiftRequests.filter((shiftRequest: any) => {
      return shiftRequest.attributes.shift_request_candidates.some(
        (candidate: any) => {
          return freelancerId === candidate.freelancer?.id
        }
      )
    })
  },
  offers: (_state: any, getters: any) => (freelancerId: number | string) => {
    const reacted = getters.reacted(freelancerId)
    const currentDate = new Date()
    currentDate.setHours(0, 0, 0, 0)
    const shiftRequests = getters.shiftRequests
    const lateShiftRequests = shiftRequests.filter(
      (shiftRequest: ShiftRequest) =>
        shiftRequest.attributes.type === 'single' &&
        new Date(shiftRequest.attributes.start_date).setHours(0, 0, 0, 0) <
          currentDate.getTime()
    )

    return shiftRequests.filter(
      (shiftRequest: ShiftRequest) =>
        !reacted.includes(shiftRequest) &&
        !lateShiftRequests.includes(shiftRequest)
    )
  },
  shiftRequests: (state) => {
    return state.shiftRequests.filter(
      (request) => request.attributes.publishedAt !== null
    )
  },
  single: (state) => {
    return state.shiftRequests.filter(
      (request) => request.attributes.type === 'single'
    )
  },
  longTerm: (state) => {
    return state.shiftRequests.filter(
      (request) => request.attributes.type === 'long-term'
    )
  },
  byId: (state: any) => (shiftId: number | string) => {
    shiftId = Number(shiftId)
    return state.shiftRequests.find(
      (shift: ShiftRequest) => shift.id === shiftId
    )
  }
}
