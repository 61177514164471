
import Vue from 'vue'
import party, { Color, Vector } from 'party-js'

export default Vue.extend({
  name: 'ConfettiComponent',
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  watch: {
    show(val) {
      if (val) {
        party.confetti(this.$refs.party2 as HTMLElement, {
          count: 75,
          speed: 300,
          size: 1,
          rotation: () => Vector.right,
          color: () => Color.fromHsl(180, 100, 70),
          shapes: ['circle']
        })
      }
    }
  },
  created() {
    this.$store?.watch(
      (state) => state.confetti.confetti,
      () => {
        const particles = this.$store.state.confetti.confetti.particles
        if (particles && this.$refs.party) {
          party.confetti(this.$refs.party as HTMLElement, {
            count: particles || party.variation.range(50, 100)
          })
          party.confetti(this.$refs.party2 as HTMLElement, {
            count: particles || party.variation.range(50, 100)
          })
          this.$store.commit('confetti/setConfetti', {})
        }
      }
    )
  }
})
