import Vue from 'vue'
import Vuetify from 'vuetify'

import en from 'vuetify/src/locale/en.ts'
import nl from 'vuetify/src/locale/nl.ts'

import colors from 'vuetify/es5/util/colors'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'

Vue.use(Vuetify)

export const themes = {
  light: {
    primary: '#2f0549',
    accent: '#4c0677',
    secondary: '#35d0c6',
    complementary: '#4c0677',
    info: colors.teal.lighten1,
    warning: colors.amber.base,
    error: colors.deepOrange.accent4,
    success: '#00B658',
    background: '#fafaff',
    background_secondary: '#ffffff',
    borderColor: '#d8d8e3',
    textColor: '#5c5c5c',
    dividerColor: '#e2e2e2',
    gradientBlue: '#e3f2fd',
    customGray: '#ECEFF4'
  },
  dark: {
    primary: '#4c0677',
    accent: '#2f0549',
    secondary: '#35d0c6',
    complementary: '#35d0c6',
    info: colors.teal.lighten1,
    warning: colors.amber.base,
    error: colors.deepOrange.accent4,
    success: '#00B658',
    background: '#161717',
    background_secondary: '#1e1e1e',
    borderColor: '#0f0f0f',
    textColor: '#d1d1d1',
    dividerColor: '#3e3e3e',
    gradientBlue: '#313e48',
    customGray: '#1d1f23'
  }
}

export default (ctx) => {
  const theme = ctx.store.getters['settings/theme']

  const vuetify = new Vuetify({
    customVariables: ['~/assets/variables.scss'],
    icons: {
      iconfont: 'mdi'
    },
    lang: {
      locales: {
        en,
        nl
      },
      current: 'nl'
    },
    theme: {
      options: {
        customProperties: true
      },
      dark: theme === 'dark',
      themes
    }
  })

  ctx.app.vuetify = vuetify
  ctx.$vuetify = vuetify.framework
}
