/**
 * The function checks if an object has any null values.
 * @param {any} target - The target parameter is the object or array that the function will check for
 * null values.
 * @param [reverse=false] - The `reverse` parameter is an optional boolean parameter that determines
 * whether the function should check for non-null values instead of null values. If `reverse` is set to
 * `true`, the function will return `true` if all values in the target object are non-null. If
 * `reverse` is not
 * @returns a boolean value. It returns `true` if the `target` object contains at least one `null`
 * value, and `false` otherwise. The `reverse` parameter is optional and when set to `true`, it returns
 * `true` if the `target` object does not contain any `null` value.
 */
const hasNull = (target: any, reverse = false) => {
  for (const member in target) {
    if (reverse) {
      if (target[member] !== null) {
        return true
      }
    } else {
      if (target[member] === null) {
        return true
      }
    }
  }
  return false
}

const removeEmpty = (obj: object) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v !== null))
}

export { hasNull, removeEmpty }
