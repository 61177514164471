export default function ({ $auth, next, localePath }) {
  if (
    $auth.loggedIn &&
    $auth.$state.user.status === 'active' &&
    $auth.$state.user.role.type !== 'client'
  ) {
    return next({ path: localePath('/') })
  } else if (
    $auth.loggedIn &&
    $auth.$state.user.status === 'active' &&
    $auth.$state.user.role.type === 'client'
  ) {
    return next({ path: localePath('/client-area') })
  }
}
