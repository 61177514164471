import { type GetterTree, type ActionTree, type MutationTree } from 'vuex'

import skillService from '~/services/skillService'

export interface Skill {
  id: number
  attributes: {
    title: string
    short_name: string
    createdAt: string
    updatedAt: string
    publishedAt: string
  }
}

export const state = () => ({
  skills: [] as Skill[]
})

export type SkillState = ReturnType<typeof state>

export const mutations: MutationTree<SkillState> = {
  set(state, skills: Skill[]) {
    state.skills = skills
  }
}

export const actions: ActionTree<SkillState, SkillState> = {
  nuxtClientInit({ dispatch }, $this) {
    dispatch('find', $this)
  },
  /** Load all available skills */
  async find({ commit }, $this) {
    try {
      const response = await skillService.findAll($this)

      if (response.data !== null && response.data.data !== null) {
        commit('set', response.data.data)
      }
    } catch (error) {
      this.$sentry.captureException(error)
    }
  }
}

export const getters: GetterTree<SkillState, SkillState> = {
  skills: (state) => state.skills,
  skillByShortName: (state) => (shortName: string) =>
    state.skills.find((skill) => skill.attributes.short_name === shortName)
}
