export default {
  namespaced: true,
  name: 'settings',
  state() {
    return {
      theme: 'auto',
      themes: ['auto', 'dark', 'light']
    }
  },
  mutations: {
    setTheme(state, theme) {
      if (state.themes.includes(theme)) state.theme = theme
    }
  },
  getters: {
    theme: (state) => {
      if (state.theme === 'auto') {
        const dark = window.matchMedia('(prefers-color-scheme: dark)')
        return dark.matches ? 'dark' : 'light'
      }
      return state.theme
    }
  }
}
