import { type GetterTree, type ActionTree, type MutationTree } from 'vuex'

import hireCompanyService from '~/services/hireCompanyService'

export interface HireCompany {
  id: number
  company_name: string
  address: string
  postalcode: string
  city: string
  country: string
  mobile_phone: string
  fixed_phone: string
  company_type: string
  kvk: number
  contact_person: string
  contact_email: string
}

const getDefaultState = () => ({
  hireCompany: null as HireCompany | null
})

export const state = getDefaultState

export type HireCompanyState = ReturnType<typeof state>

export const mutations: MutationTree<HireCompanyState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setHireCompany(state, hireCompany: HireCompany) {
    state.hireCompany = hireCompany
  },
  updateHireCompany(state, updatedHireCompany: HireCompany) {
    state.hireCompany = updatedHireCompany
  }
}

export const actions: ActionTree<HireCompanyState, HireCompanyState> = {
  nuxtClientInit({ dispatch }, $this) {
    dispatch('loadHireCompany', $this)
  },
  /** Load the current user's hire company */
  async loadHireCompany({ commit }, $this) {
    try {
      const response = await hireCompanyService.find($this)

      if (response.data !== null) {
        commit('setHireCompany', response.data)
      } else {
        commit('setHireCompany', null)
      }
    } catch (error) {
      commit('setHireCompany', null)
    }
  },
  async updateHireCompany({ commit }, { $this, updatedHireCompany }) {
    try {
      const response = await hireCompanyService.update(
        $this,
        updatedHireCompany
      )
      commit('updateHireCompany', response.data)
    } catch (error) {
      this.$sentry.captureException(error)
    }
  }
}

export const getters: GetterTree<HireCompanyState, HireCompanyState> = {
  hireCompany: (state) => {
    return state.hireCompany
  }
}
