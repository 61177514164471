
import Vue from 'vue'

export default Vue.extend({
  name: 'SnackbarComponent',
  data() {
    return {
      show: false,
      message: '',
      color: '',
      timeout: 3000
    }
  },
  created() {
    this.$store.watch(
      (state) => state.snackbar.snack,
      () => {
        const message = this.$store.state.snackbar.snack.message
        if (message) {
          this.show = true
          this.message = message

          this.color = this.$store.state.snackbar.snack.color

          if (this.color === 'success') {
            this.$store.commit('confetti/setConfetti', {
              particles: 75
            })
          }
          this.$store.commit('snackbar/setSnack', {})
        }
      }
    )
  }
})
