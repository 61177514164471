import type { GetterTree, ActionTree, MutationTree } from 'vuex'

import freelancerService from '~/services/freelancerService'

export interface Avatar {
  id: number
  name: string
  url: string
  createdAt: string
  updatedAt: string
}

export interface User {
  id: number
  avatar: Avatar
  active_wallet: number
}

export interface Client {
  name: string
}

export interface Location {
  name: string
  id: number
  client: Client
}

export interface Function {
  title: string
}

export interface ShiftRequest {
  id: number
  start_date: string
  end_date: string
  type: string
  status: string
  weekly_hours: number
  location: Location
  function: Function
}

export interface Contract {
  status: string
  createdAt: string
  id: number
  shift_request: ShiftRequest
}

export interface Freelancer {
  first_name: string
  last_name: string
  id: number
  user: User
  contracts: Contract[]
}

const getDefaultState = () => ({
  freelancers: [] as Freelancer[]
})

export const state = getDefaultState

export type FreelancerState = ReturnType<typeof state>

export const mutations: MutationTree<FreelancerState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setFreelancers(state, freelancers: Freelancer[]) {
    state.freelancers = freelancers
  }
}

export const actions: ActionTree<FreelancerState, any> = {
  async loadFreelancers({ commit }, $this) {
    try {
      const response = await freelancerService.find($this)
      if (response.data !== null) {
        commit('setFreelancers', response.data)
      }
    } catch (error) {
      commit('setFreelancers', null)
    }
  }
}

export const getters: GetterTree<FreelancerState, any> = {
  freelancers: (state) => state.freelancers,
  freelancerById: (state) => (id: number) =>
    state.freelancers.find((freelancer) => freelancer.id === id)
}
