import goCredibleService from '@/services/goCredibleService'

const getDefaultState = () => ({
  goCredibleActive: null
})

export default {
  namespaced: true,
  name: 'onboarding',
  state() {
    return getDefaultState()
  },
  mutations: {
    reset(state) {
      Object.assign(state, getDefaultState())
    },
    setGCActive(state, active) {
      state.goCredibleActive = active
    }
  },
  actions: {
    async loadOnboardingState({ commit }, $this) {
      try {
        const response = await goCredibleService.getActive($this)
        commit('setGCActive', response.data.active)
      } catch (error) {
        this.$sentry.captureException(error)
        commit('setGCActive', false)
      }
    }
  },
  getters: {
    goCredibleActive: (state) => {
      return state.goCredibleActive
    }
  }
}
