import { type GetterTree, type ActionTree, type MutationTree } from 'vuex'

import functionService from '~/services/functionService'

export interface Function {
  id: number
  attributes: {
    title: string
    big_required: boolean
    createdAt: string
    updatedAt: string
    publishedAt: string
  }
}

const getDefaultState = () => ({
  functions: [] as Function[]
})

export const state = getDefaultState

export type FunctionState = ReturnType<typeof state>

export const mutations: MutationTree<FunctionState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setFunctions(state, functions: Function[]) {
    state.functions = functions
  }
}

export const actions: ActionTree<FunctionState, FunctionState> = {
  nuxtClientInit({ dispatch }, $this) {
    dispatch('loadFunctions', $this)
  },
  /** Load all available functions */
  async loadFunctions({ commit }, $this) {
    try {
      const response = await functionService.findAll($this)

      if (response.data !== null && response.data.data !== null) {
        commit('setFunctions', response.data.data)
      }
    } catch (error) {
      this.$sentry.captureException(error)
    }
  }
}

export const getters: GetterTree<FunctionState, FunctionState> = {
  functions: (state) => {
    return state.functions
  },
  functionById: (state) => (id: number) => {
    return state.functions.find((o: Function) => o.id === id)
  }
}
