import { ApiConfig } from '../config.js'

export default {
  async getOnboarding($this) {
    const token = $this.$auth.strategy.token

    return await $this.$axios.get(ApiConfig.GOCREDIBLE.GET_ONBOARDING, {
      headers: {
        Authorization: token
      }
    })
  },

  async newOnboarding($this) {
    const token = $this.$auth.strategy.token

    return await $this.$axios.post(ApiConfig.GOCREDIBLE.NEW_ONBOARDING, null, {
      headers: {
        Authorization: token
      }
    })
  },

  async getActive($this) {
    const token = $this.$auth.strategy.token

    return await $this.$axios.get(ApiConfig.GOCREDIBLE.GET_ACTIVE, {
      headers: {
        Authorization: token
      }
    })
  },

  async deactivate($this, onboardingId, userId) {
    const token = $this.$auth.strategy.token

    return await $this.$axios.post(
      ApiConfig.GOCREDIBLE.DEACTIVATE,
      { gocredible_id: onboardingId, user_id: userId },
      {
        headers: {
          Authorization: token
        }
      }
    )
  }
}
