import { ApiConfig } from '../config.js'

export default {
  async find($this: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.get(ApiConfig.CLIENTS.GET_ALL, {
      headers: {
        Authorization: token
      }
    })
  }
}
