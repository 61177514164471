import { ActionTree, GetterTree, MutationTree } from 'vuex'
import contractService from '~/services/contractService'

import { ShiftRequest } from './shiftRequest'
import { Client } from './clients'
import { Freelancer } from './freelancer'

export interface Contract {
  id: number
  shift_request: ShiftRequest
  freelancer_rate: number
  freelancer: Freelancer
  start_date: string
  end_date: string
  status:

  | 'generated'
  | 'sign_client'
  | 'sign_freelancer'
  | 'signed'
  | 'active'
  | 'expired'
  | 'cancelled'
  | 'voided'

  contract: any
  signed_contract: any
  state: 'draft' | 'active' | 'expired' | 'cancelled'
  functions: any[]
  client: Client
}

const getDefaultState = () => ({
  contracts: [] as Contract[]
})

export const state = getDefaultState

export type ContractState = ReturnType<typeof state>

export const mutations: MutationTree<ContractState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  set(state, contracts: Contract[]) {
    state.contracts = contracts
  },
  add(state, contract: Contract) {
    state.contracts.push(contract)
  },
  deleteOne(state, contractId: number) {
    state.contracts = state.contracts.filter(
      (contract: Contract) => contract.id !== contractId
    )
  },
  voidOne(state, contractId: number) {
    const contract = state.contracts.find((c) => c.id === contractId)
    if (contract) {
      contract.status = 'voided'
    }
  }
}

export const actions: ActionTree<ContractState, ContractState> = {
  nuxtClientInit({ dispatch }, $this) {
    const role = $this?.$auth?.$state?.user?.role?.type
    if (role === 'admin') {
      dispatch('findAll', $this)
    } else if (role === 'client' || role === 'authenticated') {
      dispatch('findRestricted', $this)
    }
  },
  async findAll({ commit }) {
    try {
      const response = await contractService.findAll(this)
      const { data } = response.data
      commit('set', data)

      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },
  async findRestricted({ commit }) {
    try {
      const response = await contractService.findRestricted(this)
      const { data } = response.data
      commit('set', data)

      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },
  async create({ commit }, contractData) {
    try {
      const response = await contractService.create(this, contractData)
      commit('add', response.data)

      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },
  async delete({ commit }, contractId) {
    try {
      const response = await contractService.delete(this, { id: contractId })
      commit('deleteOne', contractId)

      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  },
  async voidContract({ commit }, data) {
    try {
      const response = await contractService.voidContract(this,
        data
      )
      commit('voidOne', data.contractId)
      return response
    } catch (error) {
      this.$sentry.captureException(error)
      throw error
    }
  }
}

export const getters: GetterTree<ContractState, ContractState> = {
  contracts: (state) => state.contracts,
  contractsByFreelancer: (state) => (freelancerId: number) =>
    state.contracts.filter(
      (contract) => contract.freelancer.id === freelancerId
    ),
  completeContracts: (state) =>
    state.contracts.filter((contract) => contract.client !== null),
  active: (state) =>
    state.contracts.filter(
      (contract) =>
        contract.status !== 'expired' &&
        contract.status !== 'cancelled' &&
        contract.status !== 'voided'
    ),
  inactive: (state) =>
    state.contracts.filter(
      (contract) =>
        contract.status === 'expired' ||
        contract.status === 'cancelled' ||
        contract.status === 'voided'
    ),
  byId: (state) => (contractId: number | string) => {
    contractId = Number(contractId)
    return state.contracts.find(
      (contract: Contract) => contract.id === contractId
    )
  }
}
