import * as qs from 'qs'

import { ApiConfig } from '../config.js'

export default {
  async findAll($this: any) {
    const token = $this.$auth.strategy.token

    const query = qs.stringify(
      { locale: $this.$i18n ? $this.$i18n.locale : 'nl' },
      { encodeValuesOnly: true }
    )
    const uri = `${ApiConfig.FUNCTION.FIND_ALL}?${query}`

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  }
}
