
import { mapGetters } from 'vuex'

import Navbar from '~/components/Navbar'
import SnackbarComponent from '@/components/SnackbarComponent'
import ConfettiComponent from '@/components/ConfettiComponent'
import PullToRefresh from '~/components/PullToRefresh.vue'
import update from '@/mixins/update'

export default {
  name: 'DefaultApp',
  components: {
    Navbar,
    SnackbarComponent,
    ConfettiComponent,
    PullToRefresh
  },
  mixins: [update],
  data: () => ({
    scrollContainer: null,
    mustActivatePayments: false
  }),
  computed: {
    ...mapGetters({ user: 'loggedInUser' }),
    theme() {
      return this.$store.getters['settings/theme']
    }
  },
  watch: {
    theme() {
      this.$vuetify.theme.dark = this.theme === 'dark'
    }
  },
  mounted() {
    this.mustActivatePayments = this.$refs?.navbar?.mustActivatePayments
    this.scrollContainer = this.$refs.scrollContainer
    this.$refs.pullRefresh.init()
    const theme = window.matchMedia('(prefers-color-scheme: dark)')
    theme.addListener((event) => {
      if (this.$store.state.settings.theme === 'auto') {
        this.$vuetify.theme.dark = event.matches
      }
    })

    this.$vuetify.theme.dark = this.theme === 'dark'

    if (this.$auth.loggedIn && this.$auth.user?.username) {
      this.$sentry.setUser(this.$auth.user.username)
    }
  },
  methods: {
    refresh() {
      window.location.reload()
    }
  }
}
