import { type GetterTree, type ActionTree, type MutationTree } from 'vuex'
import moment from 'moment'
import Vue from 'vue'
import documentService from '@/services/documentService'

export interface DocumentFile {
  id: number
  name: string
  hash: string
  mime: string
  ext: string
  url: string
  width: number | null
  height: number | null
  size: number | string | null
  caption: string | null
  alternativeText: string | null
  formats: any | null
  previewUrl: string | null
  provider: string
  provider_metadata: any | null

  createdAt: Date
  updatedAt: Date | null
}

export interface Document {
  id: number
  name: string
  type:
    | 'liability_insurance_policy_payment'
    | 'liability_insurance_policy'
    | 'behavioural_statement'
    | 'kvk_registration'
    | 'attestation'
    | 'certificate'
    | 'permit'
    | 'contract'
    | 'diploma'
    | 'identity'
    | 'cv'
    | 'bhv'
    | 'complaints_procedure'
    | 'drivers_license'
  subtype: string
  imported_from: number
  obtained_on: string | Date
  valid_until: string | Date
  verified: boolean
  competence_date: string | Date

  document: DocumentFile
  valid?: boolean
  expireSoon?: number
  expireSoonDays?: number
  expires?: boolean

  voided_by: 'authenticated' | 'client' | 'admin'
  voided_reason: string

  createdAt: Date
  updatedAt: Date | null
  publishedAt: Date | null
}
export interface GroupedBySubtype {
  [key: string]: Document[]
}

const getDefaultState = () => ({
  document: null as any | null,
  documentId: null as number | null,
  documents: [] as Document[],
  pendingDocuments: [] as Document[]
})

export const state = getDefaultState

export type DocumentState = ReturnType<typeof state>

export const mutations: MutationTree<DocumentState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setPendingDocuments(state, documents) {
    state.pendingDocuments = documents
  },
  removePendingDocument(state, id) {
    const index = state.pendingDocuments.findIndex(
      (document) => document.id === id
    )
    if (index !== -1) state.pendingDocuments.splice(index, 1)
  },
  setDocuments(state, documents) {
    state.documents = documents
  },
  setDocument(state, { document, id }) {
    state.document = document
    state.documentId = id
  },
  addDocument(state, document) {
    state.documents.push(document)
  },
  removeDocument(state, id) {
    const index = state.documents.findIndex((document) => document.id === id)
    if (index !== -1) state.documents.splice(index, 1)
  },
  updateDocumentCompetenceDateBySubtype(
    state,
    { specificSubtype, competenceDate }
  ) {
    const indexes = state.documents.reduce(
      (array: number[], document: Document, i: number) => {
        document.subtype === specificSubtype && array.push(i)

        return array
      },
      []
    )
    if (indexes.length > 0) {
      for (const index of indexes) {
        Vue.set(state.documents[index], 'competence_date', competenceDate)
      }
    }
  }
}

export const actions: ActionTree<DocumentState, DocumentState> = {
  nuxtClientInit({ dispatch }, $this) {
    dispatch('loadDocuments', $this)
  },
  async loadAllowedClientDocuments({ commit }, { $this, freelancer }) {
    try {
      const response = await documentService.getAllowedFromClient(
        $this,
        freelancer
      )
      commit('setDocuments', response.data ?? [])
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },

  async updateCompetenceDate({ commit }, { $this, data }) {
    try {
      commit('updateDocumentCompetenceDateBySubtype', data)
      await documentService.updateCompentenceDate($this, data)
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },

  async loadPendingDocuments({ commit }, $this) {
    try {
      const response = await documentService.pending($this)
      commit('setPendingDocuments', response.data ?? [])
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },
  async loadDocuments({ commit }, $this) {
    try {
      const response = await documentService.get($this)
      commit('setDocuments', response.data)
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },
  async loadDocumentFile({ commit }, { $this, id }) {
    try {
      const response = await documentService.getFile($this, id)
      commit('setDocument', { document: response.data, id })
    } catch (error) {
      this.$sentry.captureException(error)
      throw Error
    }
  },
  async addDocument({ commit }, { $this, document }) {
    try {
      const response = await documentService.create($this, document)
      commit('addDocument', response.data)
      return response
    } catch (error) {
      this.$sentry.captureException(error)
    }
  },
  async setDocumentVerified({ commit }, { $this, id, verified }) {
    try {
      await documentService.verification($this, id, verified)
      commit('removePendingDocument', id)
    } catch (error) {
      this.$sentry.captureException(error)
      throw Error
    }
  },
  async removeDocument({ commit }, { $this, id }) {
    try {
      await documentService.remove($this, id)
      commit('removeDocument', id)
    } catch (error) {
      this.$sentry.captureException(error)
      throw Error
    }
  }
}

export const getters: GetterTree<DocumentState, DocumentState> = {
  documents: (state) => {
    return state.documents.map((document) => {
      const excludedTypes = [
        'diploma',
        'kvk_registration',
        'attestation',
        'permit',
        'cv'
      ]
      if (excludedTypes.includes(document.type)) {
        document.valid = true
        document.expireSoon = 12
        document.expireSoonDays = 365
        document.expires = false
      } else {
        document.expires = true
        const isValid = moment().isBetween(
          document.obtained_on,
          document.valid_until,
          undefined,
          '()'
        )
        const expireSoon = moment(document.valid_until).diff(
          moment(),
          'months',
          true
        )
        const expireSoonDays = moment(document.valid_until).diff(
          moment(),
          'days',
          true
        )
        document.valid = isValid
        document.expireSoon = expireSoon
        document.expireSoonDays = expireSoonDays
      }
      return document
    })
  },
  pendingDocuments: (state) => {
    return state.pendingDocuments
  },
  document: (state) => {
    return state.document
  },
  documentId: (state) => {
    return state.documentId
  },
  byType: (state) => (type: string) => {
    return state.documents.find((document) => document.type === type)
  },
  bySelectedId: (state) => {
    return state.pendingDocuments.find(
      (document) => document.id === state.documentId
    )
  },
  allByType: (state) => (type: string) => {
    return state.documents.filter((document) => document.type === type)
  },
  verified: (_state, getters) => {
    const filtered = getters.documents.filter(
      (document: Document) => document.valid
    )
    return filtered.sort((a: Document, b: Document) => {
      return (
        new Date(b.obtained_on).getTime() - new Date(a.obtained_on).getTime()
      )
    })
  },
  mostRecentDocumentsBySubtype: (_state, getters) => {
    const groupedBySubtype: GroupedBySubtype = getters.documents.reduce(
      (acc: GroupedBySubtype, document: Document) => {
        if (document.type === 'certificate') {
          if (!acc[document.subtype]) {
            acc[document.subtype] = []
          }
          acc[document.subtype].push(document)
        } else {
          if (!acc[document.type]) {
            acc[document.type] = []
          }
          acc[document.type].push(document)
        }
        return acc
      },
      {}
    )

    const mostRecentBySubtype = Object.keys(groupedBySubtype).map((type) => {
      const documents = groupedBySubtype[type]
      return documents.reduce(
        (mostRecent: Document, currentDocument: Document) => {
          const mostRecentDate = new Date(mostRecent.obtained_on)
          const currentDate = new Date(currentDocument.obtained_on)
          return currentDate > mostRecentDate ? currentDocument : mostRecent
        },
        documents[0]
      )
    })

    return mostRecentBySubtype
  }
}
