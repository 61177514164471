const isAdmin = (store) => {
  const loggedInUser = store.getters.loggedInUser
  return loggedInUser && loggedInUser.role?.name === 'Admin'
}

const isClient = (store) => {
  const loggedInUser = store.getters.loggedInUser
  return loggedInUser && loggedInUser.role?.name === 'Client'
}

const isPreBoarded = async (store) => {
  const loggedInUser = store.getters.loggedInUser

  let unsubscribe = null
  let hireCompany = store.hireCompany?.hireCompany
    ? store.hireCompany?.hireCompany
    : store.state.hireCompany?.hireCompany
  if (!hireCompany) {
    await new Promise((resolve) => {
      let loaded = false
      unsubscribe = store.subscribe((mutation, store) => {
        if (mutation.type === 'hireCompany/setHireCompany') loaded = true

        if (store.loaded === true && loaded) {
          hireCompany = store.hireCompany?.hireCompany
          resolve(store.hireCompany?.hireCompany)
        }
      })
    })
  }

  if (unsubscribe) {
    unsubscribe()
  }

  const { freelancer, user_perference: userPreference } = loggedInUser
  const ok =
    loggedInUser &&
    loggedInUser.firstName &&
    loggedInUser.lastName &&
    loggedInUser.email &&
    loggedInUser.phone &&
    loggedInUser.birth_city &&
    loggedInUser.lastName &&
    loggedInUser.birth_date &&
    loggedInUser.civil_status &&
    freelancer &&
    freelancer.bsn &&
    freelancer.function &&
    freelancer.function.length > 0 &&
    !isNaN(freelancer.rate) &&
    hireCompany &&
    hireCompany.address &&
    hireCompany.country &&
    hireCompany.state &&
    hireCompany.city &&
    hireCompany.postalcode &&
    hireCompany.kvk &&
    hireCompany.vat_number &&
    userPreference &&
    !isNaN(userPreference.max_travel) &&
    loggedInUser.status === 'active'

  if (
    freelancer?.function[0]?.function?.big_required &&
    !loggedInUser.big_register_number
  ) {
    return false
  }

  return ok
}

export default async function (context) {
  const { store, $auth, next, localePath } = context
  if (!store || !$auth || !next || !localePath) {
    return true
  }

  if (isAdmin(store)) {
    return true
  }

  if (isClient(store)) {
    return next({ path: localePath('/client-area') })
  }

  const preBoarded = await isPreBoarded(store)
  if (!preBoarded) {
    return next({ path: localePath('/pre-boarding') })
  }

  // await $auth.fetchUser()
  // if (
  //   store.getters['onboarding/goCredibleActive'] === null &&
  //   !isAdmin(store)
  // ) {

  // if (!store.getters['onboarding/goCredibleActive'] && !isAdmin(store)) {
  //   return next({ path: localePath('/onboarding') })
  // }
}
