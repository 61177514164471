export default async function ({ app, route, next, localePath }) {
  const local = await app.$cookies.get('auth._token.local')
  const token = await app.$cookies.get('auth._token.token')
  if (!local && !token) {
    return next({
      path: localePath('/login'),
      query: { redirect: route.fullPath }
    })
  }
}
