import { type GetterTree, type ActionTree, type MutationTree } from 'vuex'

import clientService from '~/services/clientService'

export interface Location {
  id: number
  name: string
  postalCode?: string
  address?: string
  city?: string
  geojson?: any
  description?: string
  work_types?: any
  skills?: any
  publishedAt?: null | Date
  weekplanner?: any
  contact_name?: string
  contact_email?: string
}

export interface Client {
  id: number
  name: string
  postalCode: string
  address: string
  city: string
  country: string
  kvk: number
  description?: string
  contact_name: string
  contact_email: string

  locations: Location[]
}

const getDefaultState = () => ({
  clients: [] as Client[]
})

export const state = getDefaultState

export type ClientState = ReturnType<typeof state>

export const mutations: MutationTree<ClientState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setShifts(state, clients: Client[]) {
    state.clients = clients
  }
}
export const actions: ActionTree<ClientState, ClientState> = {
  nuxtClientInit({ dispatch }, $this) {
    dispatch('loadClients', $this)
  },
  /** Load all upcoming and pending freelancer shifts */
  async loadClients({ commit }, $this) {
    try {
      const response = await clientService.find($this)

      if (response.data.length > 0) {
        commit('setShifts', response.data)
      }
    } catch (error) {
      $this.$sentry.captureException(error)
    }
  }
}

export const getters: GetterTree<ClientState, ClientState> = {
  clients: (state) => {
    return state.clients
  }
}
