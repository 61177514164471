import Vue from 'vue'

import * as qs from 'qs'

import { ApiConfig } from '../config.js'
import { Pagination } from '~/types/pagination'

import { Shift, UpdateShift } from '~/store/shifts'

export default {
  async upcoming($this: any, pagination: Pagination | null = null) {
    const token = $this.$auth.strategy.token
    let uri = ApiConfig.SHIFTS.GET_UPCOMING
    if (pagination) {
      const query = qs.stringify(
        {
          pagination: {
            page: pagination.page || 1,
            pageSize: pagination.pageSize || 10
          }
        },
        { encodeValuesOnly: true }
      )
      uri = `${uri}?${query}`
    }

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },

  async pending($this: any, pagination: Pagination | null = null) {
    const token = $this.$auth.strategy.token
    let uri = ApiConfig.SHIFTS.GET_PENDING
    if (pagination) {
      const query = qs.stringify(
        {
          pagination: {
            page: pagination.page || 1,
            pageSize: pagination.pageSize || 10
          }
        },
        { encodeValuesOnly: true }
      )
      uri = `${uri}?${query}`
    }

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },

  async approved($this: any, pagination: Pagination | null = null) {
    const token = $this.$auth.strategy.token
    let uri = ApiConfig.SHIFTS.GET_APPROVED
    if (pagination) {
      const query = qs.stringify(
        {
          pagination: {
            page: pagination.page || 1,
            pageSize: pagination.pageSize || 10
          }
        },
        { encodeValuesOnly: true }
      )
      uri = `${uri}?${query}`
    }

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },

  async worked($this: any, pagination: Pagination | null = null) {
    const token = $this.$auth.strategy.token
    let uri = ApiConfig.SHIFTS.GET_WORKED
    if (pagination) {
      const query = qs.stringify(
        {
          pagination: {
            page: pagination.page || 1,
            pageSize: pagination.pageSize || 10
          }
        },
        { encodeValuesOnly: true }
      )
      uri = `${uri}?${query}`
    }

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },

  async findOne($this: any, id: number) {
    const token = ($this.$auth.strategy as any).token

    const query = qs.stringify({ id }, { encodeValuesOnly: true })
    const uri = `${ApiConfig.SHIFTS.FIND_ONE}?${query}`

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },

  async create($this: Vue, shift: Shift) {
    const token = ($this.$auth.strategy as any).token
    return await $this.$axios.post(ApiConfig.SHIFTS.CREATE, shift, {
      headers: {
        Authorization: token
      }
    })
  },

  async update($this: Vue, shift: UpdateShift) {
    const token = ($this.$auth.strategy as any).token
    return await $this.$axios.put(ApiConfig.SHIFTS.UPDATE, shift, {
      headers: {
        Authorization: token
      }
    })
  },

  async approve($this: Vue, shift: UpdateShift) {
    const token = ($this.$auth.strategy as any).token
    return await $this.$axios.put(ApiConfig.SHIFTS.APPROVE, shift, {
      headers: {
        Authorization: token
      }
    })
  },

  async approveChanges($this: Vue, id: number) {
    const token = ($this.$auth.strategy as any).token
    return await $this.$axios.put(
      ApiConfig.SHIFTS.APPROVE_CHANGES,
      { id },
      {
        headers: {
          Authorization: token
        }
      }
    )
  },

  async delete($this: any, id: number) {
    const token = ($this.$auth.strategy as any).token

    const query = qs.stringify({ id }, { encodeValuesOnly: true })
    const uri = `${ApiConfig.SHIFTS.DELETE}?${query}`

    return await $this.$axios.delete(uri, {
      headers: {
        Authorization: token
      }
    })
  },

  async findByClient($this: any, week: number, year: number) {
    const token = ($this.$auth.strategy as any).token

    const query = qs.stringify({ week, year }, { encodeValuesOnly: true })
    const uri = `${ApiConfig.SHIFTS.FIND_BY_LOCATION}?${query}`

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },

  async approveByClient($this: any, shifts: number[]) {
    const token = ($this.$auth.strategy as any).token
    return await $this.$axios.post(
      ApiConfig.SHIFTS.APPROVE_BY_LOCATION,
      {
        shifts
      },
      {
        headers: {
          Authorization: token
        }
      }
    )
  },

  async rejectByClient($this: any, shift: UpdateShift) {
    const token = ($this.$auth.strategy as any).token
    return await $this.$axios.post(
      ApiConfig.SHIFTS.REJECT_BY_LOCATION,
      {
        shift
      },
      {
        headers: {
          Authorization: token
        }
      }
    )
  },

  async findAll($this: any, week: number, year: number) {
    const token = ($this.$auth.strategy as any).token

    const query = qs.stringify({ week, year }, { encodeValuesOnly: true })
    const uri = `${ApiConfig.SHIFTS.FIND_ALL}?${query}`

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  }
}
