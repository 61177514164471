import * as qs from 'qs'

import { ApiConfig } from '../config.js'

export default {
  async create($this: any, id: number, data: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.post(
      ApiConfig.SKILL.CREATE.replace('%d', String(id)),
      data,
      {
        headers: {
          Authorization: token
        }
      }
    )
  },

  async findAll($this: any) {
    const token = $this.$auth.strategy.token

    const query = qs.stringify(
      { locale: $this.$i18n ? $this.$i18n.locale : 'nl' },
      { encodeValuesOnly: true }
    )
    const uri = `${ApiConfig.SKILL.FIND_ALL}?${query}`

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  }
}
