
import Vue from 'vue'
import { mapGetters, mapState } from 'vuex'

import moment, { type Moment } from 'moment'
import { type LocaleObject } from '@nuxtjs/i18n'

export default Vue.extend({
  name: 'NavBar',
  data() {
    return {
      drawer: false,
      group: null,
      right: null,
      phoneNumber: '+31 (0)40 30 41 112',
      date: moment()
    }
  },
  computed: {
    ...mapGetters({
      contracts: 'contract/contracts',
      onboarding: 'onboarding/goCredibleActive',
      isAuthenticated: 'isAuthenticated',
      loggedInUser: 'loggedInUser',
      currentTheme: 'settings/theme'
    }),
    /** Get all themes */
    ...mapState({
      themes: (state: any) => state.settings.themes
    }),
    /** Current year */
    isAdmin(): boolean {
      return this.loggedInUser.role.name === 'Admin'
    },
    mustActivatePayments(): boolean {
      if (
        this.isAuthenticated &&
        this.loggedInUser.role.name === 'Authenticated'
      ) {
        if (this.onboarding || this.onboarding === null || !this.contracts) {
          return false
        }
        if (this.loggedInUser?.status === 'pending') {
          return false
        }
        for (const contract of this.contracts) {
          if (contract?.status === 'signed' || contract?.status === 'active') {
            return true
          }
        }
        return false
      }
      return false
    },
    isOnboarding(): boolean {
      return this.$route.path === this.localePath('/client-area/onboarding')
    },
    /** Current year */
    year(): number {
      return new Date().getFullYear()
    },
    /** Current theme */
    theme: {
      get(): string {
        return this.$store.state.settings.theme
      },
      set(newTheme: string) {
        this.$store.commit('settings/setTheme', newTheme)
      }
    },
    /** Check if the user completed the onboarding */
    navigationAllowed(): boolean {
      if ((this.$auth.user?.role as any)?.name === 'Admin') return true
      return true
    },
    /** Check if the user is a client */
    clientNavigationAllowed(): boolean {
      if (
        (this.$auth.user?.role as any)?.name === 'Client' &&
        this.$auth.user?.status === 'active'
      )
        return true
      return false
    },
    /** Available languages */
    availableLocales(): LocaleObject[] {
      return (this.$i18n.locales as LocaleObject[]).filter(
        (i) => i.code !== this.$i18n.locale
      )
    },
    /** Current language */
    currentLocale(): LocaleObject | undefined {
      return (this.$i18n.locales as LocaleObject[]).find(
        (i) => i.code === this.$i18n.locale
      )
    },
    /** Returns all navigation items */
    navigationItems(): any[] {
      if (
        this.loggedInUser.role.type === 'client' &&
        this.$vuetify.breakpoint.mdAndDown
      ) {
        return [
          {
            path: this.localePath('/client-area'),
            title: this.$t('dashboard')
          },
          {
            path: this.localePath('/client-area/work/requests'),
            title: this.$t('requests')
          },
          {
            path: this.localePath('/client-area/approval'),
            title: this.$t('approve')
          },
          {
            path: this.localePath('/client-area/contract/manage'),
            title: this.$t('contract.multiple')
          },
          {
            path: this.localePath('/freelancers'),
            title: this.$t('freelancer.multiple')
          }
        ]
      } else {
        return [
          {
            path: this.localePath('/profile'),
            icon: 'mdi-view-dashboard',
            title: this.$t('my_profile')
          },
          {
            path: '/',
            icon: 'mdi-receipt',
            title: this.$t('settings')
          },
          {
            path: '/',
            icon: 'mdi-receipt',
            title: this.$t('faq')
          },
          {
            path: '/',
            icon: 'mdi-receipt',
            title: this.$t('contact')
          }
        ]
      }
    },
    /** Returns all navigation items */
    navigationItemsDesktop(): any[] {
      if (this.clientNavigationAllowed) {
        return [
          {
            path: this.localePath('/client-area'),
            title: this.$t('dashboard')
          },
          {
            path: this.localePath('/client-area/work/requests'),
            title: this.$t('requests')
          },
          {
            path: this.localePath('/client-area/approval'),
            title: this.$t('approve')
          },
          {
            path: this.localePath('/client-area/contract/manage'),
            title: this.$t('contract.multiple')
          },
          {
            path: this.localePath('/freelancers'),
            title: this.$t('freelancer.multiple')
          },
          {
            path: this.localePath('/client-area/invoices/pending'),
            title: this.$t('invoice.multiple')
          }
        ]
      } else {
        if (this.isAdmin) {
          return [
            {
              path: this.localePath('/documents/validate'),
              title: this.$t('documents')
            },
            {
              path: this.localePath('/freelancers'),
              title: this.$t('freelancer.multiple')
            },
            {
              path: this.localePath('/planning/admin'),
              title: this.$t('user.dashboard.planning')
            },
            {
              path: this.localePath('/invoices/pending'),
              title: this.$t('invoices')
            }
          ]
        }

        return [
          {
            path: this.localePath('/planning/pending'),
            title: this.$t('approve_shifts')
          },
          {
            path: this.localePath('/documents/registry'),
            title: this.$t('documents')
          },
          {
            path: this.localePath('/contracts/active'),
            title: this.$t('contract.multiple')
          },
          {
            path: this.localePath('/invoices/pending'),
            title: this.$t('invoices')
          },
          {
            path: this.localePath('/planning/upcoming'),
            title: this.$t('user.dashboard.planning')
          },
          {
            path: this.localePath('/jobs/offers'),
            title: this.$t('offers')
          }
        ]
      }
    }
  },
  watch: {
    group() {
      this.drawer = false
    }
  },
  methods: {
    /** Destroy user session on the client and server */
    async logout() {
      await this.$auth.logout()
      this.$router.replace(this.localeLocation('/login') || '/login')
      await this.$store.dispatch('resetStore')
    },
    /** Switch the app theme */
    switchTheme() {
      if (this.theme === this.themes[0]) {
        this.theme = this.themes[1]
      } else if (this.theme === this.themes[1]) {
        this.theme = this.themes[2]
      } else if (this.theme === this.themes[2]) {
        this.theme = this.themes[0]
      }
    },
    /** Copy text */
    copy() {
      try {
        navigator.clipboard.writeText(this.phoneNumber)
        this.$store.commit('snackbar/setSnack', {
          message: this.$t('messages.success.copy_success'),
          color: 'success'
        })
      } catch (e) {
        this.$store.commit('snackbar/setSnack', {
          message: this.$t('messages.error.copy_error'),
          color: 'error'
        })
      }
    }
  }
})
