import * as qs from 'qs'

import { ApiConfig } from '../config.js'

export default {
  async getAllowedFromClient($this, freelancer) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.DOCUMENTS.GET_ALLOWED_CLIENT
    const query = qs.stringify(
      {
        freelancer
      },
      { encodeValuesOnly: true }
    )

    return await $this.$axios.get(`${uri}?${query}`, {
      headers: {
        Authorization: token
      }
    })
  },
  async get($this) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.DOCUMENTS.GET

    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },
  async getFile($this, id) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.DOCUMENTS.GET_FILE.replace('%d', id)

    return await $this.$axios.get(uri, {
      responseType: 'blob',
      headers: {
        Authorization: token
      }
    })
  },
  async pending($this) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.DOCUMENTS.GET_ALL

    const params = {
      verified: false
    }

    return await $this.$axios.get(uri, {
      params,
      headers: {
        Authorization: token
      }
    })
  },
  async verification($this, id, verified) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.DOCUMENTS.VERIFICATION.replace('%d', id)

    const body = {
      verified
    }

    return await $this.$axios.put(uri, body, {
      headers: {
        Authorization: token
      }
    })
  },
  async create($this, document) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.DOCUMENTS.CREATE

    return await $this.$axios.post(uri, document, {
      headers: {
        Authorization: token
      }
    })
  },

  async emrex($this, data) {
    return await $this.$axios.post(
      data.url,
      {
        sessionId: data.sessionId,
        returnUrl: data.returnUrl
      },
      {
        headers: {
          Authorization: data.signature
        }
      }
    )
  },

  async remove($this, documentId) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.DOCUMENTS.DELETE.replace('%d', documentId)

    return await $this.$axios.delete(uri, {
      headers: {
        Authorization: token
      }
    })
  },
  async updateCompentenceDate($this, data) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.DOCUMENTS.UPDATE
    return await $this.$axios.put(uri, data, {
      headers: {
        Authorization: token
      }
    })
  }
}
