import { type GetterTree, type ActionTree, type MutationTree } from 'vuex'

import workTypeService from '~/services/workTypeService'

export interface WorkType {
  id: number
  attributes: {
    title: string
    description: string
    short_name: string
    createdAt: string
    updatedAt: string
    publishedAt: string
  }
}

export const state = () => ({
  workTypes: [] as WorkType[]
})

export type WorkTypeState = ReturnType<typeof state>

export const mutations: MutationTree<WorkTypeState> = {
  set(state, workType: WorkType[]) {
    state.workTypes = workType
  }
}

export const actions: ActionTree<WorkTypeState, WorkTypeState> = {
  nuxtClientInit({ dispatch }, $this) {
    dispatch('load', $this)
  },
  /** Load all available workTypes */
  async load({ commit }, $this) {
    try {
      const response = await workTypeService.findAll($this)

      if (response.data !== null && response.data.data !== null) {
        commit('set', response.data.data)
      }
    } catch (error) {
      this.$sentry.captureException(error)
    }
  }
}

export const getters: GetterTree<WorkTypeState, WorkTypeState> = {
  workTypes: (state) => {
    return state.workTypes
  },
  workType: (state) => (shortName: string) => {
    return state.workTypes.find(
      (workType) => workType.attributes.short_name === shortName
    )
  }
}
