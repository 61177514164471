import { ApiConfig } from '../config.js'

export default {
  async find($this: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.get(ApiConfig.HIRE_COMPANY.GET, {
      headers: {
        Authorization: token
      }
    })
  },
  async update($this: any, updatedHireCompanyData: any) {
    const token = $this.$auth.strategy.token
    return await $this.$axios.put(ApiConfig.HIRE_COMPANY.UPDATE_ADDRESS, updatedHireCompanyData, {
      headers: {
        Authorization: token
      }
    })
  }

}
