import { render, staticRenderFns } from "./default.vue?vue&type=template&id=0e771c35&scoped=true"
import script from "./default.vue?vue&type=script&lang=js"
export * from "./default.vue?vue&type=script&lang=js"
import style0 from "./default.vue?vue&type=style&index=0&id=0e771c35&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e771c35",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Navbar: require('/usr/src/app/frontend/components/Navbar.vue').default,PullToRefresh: require('/usr/src/app/frontend/components/PullToRefresh.vue').default,SnackbarComponent: require('/usr/src/app/frontend/components/SnackbarComponent.vue').default,ConfettiComponent: require('/usr/src/app/frontend/components/ConfettiComponent.vue').default})
