import { Freelancer } from './shifts'
import userService from '@/services/userService'

export interface User {
  id: number
  username: string
  email: string

  phone: string
  firstName: string
  lastName: string
  freelancer: Freelancer

  gocredible_id: number
  active_wallet: number

  confirmed: boolean
  blocked: boolean
}

const getDefaultState = () => ({
  users: [] as User[]
})

export default {
  namespaced: true,
  name: 'onboarding',
  state() {
    return getDefaultState()
  },
  mutations: {
    reset(state: any) {
      Object.assign(state, getDefaultState())
    },
    set(state: any, users: User[]) {
      state.users = users
    },
    removeWallet(state: any, userId: number) {
      const user = state.users.find((user: User) => user.id === userId)
      if (user) {
        user.active_wallet = null
      }
    }
  },
  actions: {
    async fetch({ commit }: any, $this: any) {
      try {
        const response = await userService.fetchAll($this)
        commit('set', response.data)
      } catch (error) {
        $this.$sentry.captureException(error)
      }
    }
  },
  getters: {
    users: (state: any) => {
      return state.users
    },
    onboardedUsers: (state: any) => {
      const users = state.users.filter((user: User) => {
        if (
          !user.blocked &&
          user.confirmed &&
          user.phone &&
          user.firstName &&
          user.lastName &&
          user.email &&
          user.active_wallet &&
          user.gocredible_id &&
          user.freelancer &&
          user.freelancer.freelancer_datastore &&
          user.freelancer.function &&
          user.freelancer.function.length > 0 &&
          user.freelancer.rate &&
          user.freelancer.vat
        ) {
          return true
        } else {
          return false
        }
      })
      return users
    }
  }
}
