import { type GetterTree, type ActionTree, type MutationTree } from 'vuex'

import { Location } from './clients'
import { Freelancer } from './shifts'

import invoiceService from '@/services/invoiceService'

export interface File {
  id: number
  url: string
  size: number
  ext: string
  mime: string
  name: string
}

export interface Payment {
  id: number
  payment_id: string
  payment_status: string
  createdAt: string
  updatedAt: string
}

export interface InvoiceTotal {
  freelancer_id: number
  total: number
  vat: number
}

export interface Invoice {
  id: number
  identifier: string
  date: string
  payment: Payment | null
  location: Location
  freelancer: Freelancer | null
  freelancer_invoice_totals?: InvoiceTotal | null
  freelancer_invoices: File[]
}

const getDefaultState = () => ({
  invoices: [] as Invoice[] | null
})

export const state = getDefaultState

export type InvoiceState = ReturnType<typeof state>

export const mutations: MutationTree<InvoiceState> = {
  reset(state) {
    Object.assign(state, getDefaultState())
  },
  setInvoices(state, invoices: Invoice[]) {
    state.invoices = invoices
    state.invoices.sort(
      (a: Invoice, b: Invoice) =>
        new Date(b.date).getTime() - new Date(a.date).getTime()
    )
  },

  setPaymentInfo(state, { identifier, payment }) {
    const { invoices } = state
    const invoice = invoices?.find((x) => x.identifier === identifier)
    if (invoice) invoice.payment = payment
  }
}

export const actions: ActionTree<InvoiceState, InvoiceState> = {
  // nuxtClientInit({ dispatch }, $this) {
  //   dispatch('loadHireCompany', $this)
  // },
  /** Load all invoices */
  async loadInvoices({ commit }, $this) {
    try {
      const response = await invoiceService.get($this)
      commit('setInvoices', response.data)
    } catch (error) {
      this.$sentry.captureException(error)
    }
  }
}

export const getters: GetterTree<InvoiceState, InvoiceState> = {
  invoices: (state) => {
    return state.invoices
  },

  pending: (state) => {
    return (state.invoices as Invoice[]).filter(
      (invoice) =>
        invoice.payment !== null &&
        invoice.payment.payment_status !== 'paid' &&
        invoice.payment.payment_status !== 'payout_processing' &&
        invoice.payment.payment_status !== 'paidout'
    )
  },

  finalized: (state: any) => {
    return (state.invoices as Invoice[]).filter(
      (invoice) =>
        invoice.payment === null ||
        invoice.payment.payment_status === 'paid' ||
        invoice.payment.payment_status === 'payout_processing' ||
        invoice.payment.payment_status === 'paidout'
    )
  }
}
