import { ApiConfig } from '../config.js'

export default {
  async findAll($this: any) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.FAVORITE_FREELANCERS.FIND_ALL
    return await $this.$axios.get(uri, {
      headers: {
        Authorization: token
      }
    })
  },

  async toggle($this: any, data: any) {
    const token = $this.$auth.strategy.token
    const uri = ApiConfig.FAVORITE_FREELANCERS.TOGGLE
    return await $this.$axios.post(uri, data, {
      headers: {
        Authorization: token
      }
    })
  }
}
